import {Card, Flex, theme} from "antd";
import React from "react";
import styled from "styled-components";
import {ColoredValuePercents} from "../components/ColoredValuePercents";
import {Params, useLoaderData} from "react-router-dom";
import {MarginValue} from "../components/MarginValue";
import {timestampToDateTimeString, timestampToTimeString} from "../helpers/FormatDate";
import {privateApi} from "../config/AxiosConfig";
import {Total, Totals} from "../components/Total";
import {DrawdownView} from "../components/DrawdownView";
import {UnlockerTotal, UnlockerTotals} from "../components/UnlockerTotal";

const {useToken} = theme;

interface AccountResponseType {
    // id: string,
    name: string,
    days: number,
    // type: string,
    currency: string,
    today: number,
    todayPercent: number,
    drawdown: number,
    drawdownPercent: number,
    balance: number,
    equity: number,
    autoTrading: number,
    marginLevel: number,
    // total: number,
    // totalPercent: number
    sellOrdersCount: number,
    buyOrdersCount: number,
    hasUnlocker: boolean,
    sellOrdersVolume: number,
    buyOrdersVolume: number,
    maxTodayDrawdawn: number,
    maxTodayDrawdawnPercent: number,
    maxTodayDrawdawnTime?: number,
    maxPercentTodayDrawdawn: number,
    maxPercentTodayDrawdawnPercent: number,
    maxPercentTodayDrawdawnTime?: number
    maxDrawdawn: number,
    maxDrawdawnPercent: number,
    maxDrawdawnTime?: number,
    maxPercentDrawdawn: number,
    maxPercentDrawdawnPercent: number,
    maxPercentDrawdawnTime?: number,
    totals: Totals
    unlockerTotals: UnlockerTotals
}

const gridStyle: React.CSSProperties = {
    width: '50%',
    textAlign: 'center',
    padding: '10px'
};

const BlockTitle = styled.div`
    font-size: 12px;
    text-transform: uppercase;
`

export async function accountLoader({params}: { params: Params<"accountId"> }) {
    const response = await privateApi(`/accounts/${params.accountId}`, {withCredentials: true})
    return response.data
}

export const Account = () => {
    const {token} = useToken();
    const accountData: AccountResponseType = useLoaderData() as AccountResponseType;
    return (
        <>
            <Card title={accountData.name} style={{maxWidth: "400px", margin: "0 auto", marginBottom: 17}}>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Просадка</BlockTitle>
                    <ColoredValuePercents value={accountData.drawdownPercent} style={{fontSize: "30px"}}/>
                    <div
                        style={{fontSize: "11px", fontWeight: "700"}}>{accountData.drawdown}{accountData.currency}</div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Сегодня</BlockTitle>
                    <ColoredValuePercents value={accountData.todayPercent} style={{fontSize: "30px"}}/>
                    <div style={{fontSize: "11px", fontWeight: "700"}}>{accountData.today}{accountData.currency}</div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Баланс</BlockTitle>
                    <div
                        style={{fontSize: "20px", color: token.cyan7}}>{accountData.balance}{accountData.currency}</div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Эквити</BlockTitle>
                    <div style={{fontSize: "20px", color: token.cyan7}}>{accountData.equity}{accountData.currency}</div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Авто торговля</BlockTitle>
                    <div style={{fontSize: "20px"}}>{accountData.autoTrading}%</div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Уровень маржи</BlockTitle>
                    <MarginValue value={accountData.marginLevel} style={{fontSize: "20px"}}/>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Макс. Просадка</BlockTitle>
                    <Flex justify="space-between" align="start">
                        <DrawdownView
                            percents={accountData.maxTodayDrawdawnPercent}
                            money={accountData.maxTodayDrawdawn}
                            currency={accountData.currency}
                            type={accountData.currency}
                            time={timestampToTimeString(accountData.maxTodayDrawdawnTime)}
                        />
                        <div style={{fontSize: "10px"}}>за день</div>
                        <DrawdownView
                            percents={accountData.maxPercentTodayDrawdawnPercent}
                            money={accountData.maxPercentTodayDrawdawn}
                            currency={accountData.currency}
                            type="%"
                            time={timestampToTimeString(accountData.maxPercentTodayDrawdawnTime)}
                        />
                    </Flex>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <BlockTitle>Макс. Просадка</BlockTitle>
                    <Flex justify="space-between" align="start">
                        <DrawdownView
                            percents={accountData.maxDrawdawnPercent}
                            money={accountData.maxDrawdawn}
                            currency={accountData.currency}
                            type={accountData.currency}
                            time={timestampToDateTimeString(accountData.maxDrawdawnTime)}
                        />
                        <div style={{fontSize: "10px"}}>общая</div>
                        <DrawdownView
                            percents={accountData.maxPercentDrawdawnPercent}
                            money={accountData.maxPercentDrawdawn}
                            currency={accountData.currency}
                            type="%"
                            time={timestampToDateTimeString(accountData.maxPercentDrawdawnTime)}
                        />
                    </Flex>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <Flex justify="space-between" align="center">
                        <div style={{fontSize: "10px"}}>кол-во</div>
                        <BlockTitle style={{fontSize: "20px"}}>Sell</BlockTitle>
                        <div style={{fontSize: "10px"}}>объем</div>
                    </Flex>
                    <Flex justify="space-between" align="center">
                        <div style={{fontSize: "20px", color: token.colorError}}>{accountData.sellOrdersCount}</div>
                        <div style={{fontSize: "20px", color: token.colorError}}>{accountData.sellOrdersVolume}</div>
                    </Flex>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <Flex justify="space-between" align="center">
                        <div style={{fontSize: "10px"}}>кол-во</div>
                        <BlockTitle style={{fontSize: "20px"}}>Buy</BlockTitle>
                        <div style={{fontSize: "10px"}}>объем</div>
                    </Flex>
                    <Flex justify="space-between" align="center">
                        <div style={{fontSize: "20px", color: token.colorSuccess}}>{accountData.buyOrdersCount}</div>
                        <div style={{fontSize: "20px", color: token.colorSuccess}}>{accountData.buyOrdersVolume}</div>
                    </Flex>
                </Card.Grid>
            </Card>
            {accountData.hasUnlocker && <UnlockerTotal totals={accountData.unlockerTotals}/>}
            <Total totals={accountData.totals}/>
        </>
    )
}