import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {ColoredMoney} from "./ColoredMoney";
import {groupBy} from "../helpers/Collections";
import React from "react";

export interface UnlockerTotals {
    months: UnlockerTotalType[],
    currency: String
}

interface UnlockerTotalType {
    yearMonth: YearMonthType
    drawdown: number,
    unlocked: number
}

interface YearMonthType {
    year: number,
    month: number
}

interface TableDataType {
    totals: UnlockerTotals
}

interface DataType {
    key: React.Key,
    month: number,
    drawdown?: number,
    unlocked?: number,
    children?: DataType[];
}

const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

const sharedOnCell = (row: DataType) => row.month > 12 ? { colSpan: 0 } : {}

const columns: ColumnsType<DataType> = [
    {
        title: 'Месяц',
        dataIndex: 'month',
        key: 'month',
        render: (monthNumber) =>
            monthNumber <= 12
                ? monthNames[monthNumber-1]
                : <div style={{ textAlign: "center" }}>{monthNumber}</div>,
        onCell: (row, index) => ({
            colSpan: row.month > 12 ? 3 : 1,
        }),
    },
    {
        title: 'Разблокировано',
        dataIndex: 'unlocked',
        key: 'unlocked',
        ellipsis: true,
        render: (value) => value && <ColoredMoney value={value} style={{fontSize:"18px"}} currency={'$'}/>, //TODO передавать значение валюты из ответа
        onCell: sharedOnCell
    },
    {
        title: 'Просадка',
        dataIndex: 'drawdown',
        key: 'drawdown',
        render: (value) => value && <ColoredMoney value={value} style={{fontSize:"18px"}} currency={'$'}/>, //TODO передавать значение валюты из ответа
        onCell: sharedOnCell
    }
];

function toDataType(data: UnlockerTotalType[]): DataType[] {
    const groupedMap = groupBy(data, (v: UnlockerTotalType) => v.yearMonth.year)
    const rows: DataType[] = [];
    for (const entry of Array.from( groupedMap.entries())) {
        rows.push({
            key: entry[0],
            month: entry[0],
            children: entry[1].map(current => ({
                key: current.yearMonth.year + "." + current.yearMonth.month,
                month: current.yearMonth.month,
                drawdown: current.drawdown,
                unlocked: current.unlocked
            }))
        })
    }
    return rows;
}

export const UnlockerTotal = ({totals}: TableDataType) => {
    return (
        <>
            <Table
                columns={columns}
                expandable={{ defaultExpandAllRows: true, indentSize: 0, showExpandColumn: false }}
                dataSource={toDataType(totals.months)}
                pagination={false}
                size="middle"
            />;
        </>
    )
}